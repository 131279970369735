(function(window) {
    'use strict';

    const config = {
        trackingId: 'UA-136240420-1'
    };

    // Expose config
    window.chiyoung = window.chiyoung || {};
    window.chiyoung.config = config;
})(window);
